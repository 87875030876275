<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header text-white">
            Vehicle Search
          </div>
          <div class="card-body">
            <p class="text-warning">You must provide the full registration number when searching for the vehicle. This will only search clients which you have access to.</p>

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Vehicle Registration: <span class="tx-danger">*</span></label>
                  <input class="form-control form-control-dark" type="text" v-model="registration" name="registration" placeholder="Enter registration">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" style="width: 100%;">&nbsp;</label>
                  <button :disabled="searching" class="btn btn-info" @click="search">Search for Vehicle</button>
                </div>
              </div>
            </div>

            <h1 v-if="searching">{{searched}} of {{numberOfClients}} customers checked...</h1>

            <table class="table">
              <thead>
                <tr>
                  <th>Client</th>
                  <th># of Events</th>
                  <th># of Claims</th>
                  <th>Blacklisted?</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="clients.length < 1">
                  <td colspan="4" class="text-center">No records found for the vehicle.</td>
                </tr>
                <tr v-for="client of clients" :key="client.id + 'client'">
                  <td>{{client.name}}</td>
                  <td>{{client.events}}</td>
                  <td>{{client.claims.length}}</td>
                  <td><i class="fa" v-bind:class="{'fa-check text-success': client.blacklist.length > 0, 'fa-times text-danger': client.blacklist.length < 1}"></i></td>
                </tr>
              </tbody>
            </table>

            <p class="text-white"><strong>System Logs</strong></p>

            <table class="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Site</th>
                  <th>User</th>
                  <th>Timestamp</th>
                  <th>Vehicle</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="log in logs" :key="log.id + 'log'">
                  <td>{{log.description}}</td>
                  <td>{{ log.name }}</td>
                  <td>{{log.email}}</td>
                  <td>{{log.created_at}}</td>
                  <td>{{log.vehicle?.plate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
  name: "VehicleSearch",
  data(){
    return {
      registration: "",
      clients: [],
      vehicleNonExistent: false,
      logs: [],
      numberOfClients: 0,
      searched: 0,
      searching: false
    }
  },
  mounted(){
    
  },
  methods: {
    async search(){
      this.numberOfClients = this.$store.state.auth.user.clients.length;
      this.searched = 0;
      this.searching = true;
      this.clients = [];
      this.logs = [];

      let registration = this.registration.toUpperCase();

      if(registration.includes("?")){
        registration = registration.replace("?", "%3F");
      }

      if(registration.includes("?")){
        this.$error("Registration number cannot contain more than one question mark.");
        return;
      }

      axios.get(`https://api.varsanpr.com/api/vehicle/${registration.replace(/\s/g, '')}/locate?client_id=0`, {
        headers: authHeader()
      })
      .then(async response => {
        this.logs = response.data.logs;
        this.logs.forEach((log) => {
          log.created_at = DateTime.fromSeconds(log.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
        })
        for await (let client of this.$store.state.auth.user.clients){
          this.searched++;
          await this.searchVehicle(client);
        }
        this.searching = false;
      })
      .catch(error => {
        this.$error("Vehicle could not be found.", error);
      })
    },
    async searchVehicle(client){

      let registration = this.registration.replace("?", "%3F").replace(/\s/g, '');
      return axios.get(`https://api.varsanpr.com/api/vehicle/${registration}/locate?client_id=${client.id}`, {
        headers: authHeader()
      })
      .then(response => {
        if(response.data.success === true){
          if(response.data.events > 0 || response.data.claims.length > 0 || response.data.blacklist.length > 0){
            this.clients.push({id: client.id, name: client.name, events: response.data.events, claims: response.data.claims, blacklist: response.data.blacklist});
          }
        }
      })
      .catch(error => {
        this.$error("Failed to locate vehicle for " + client.name, error);
      });

    }
  }
}
</script>